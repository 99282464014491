<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header header-elements-inline bg-secondary" >
      <h5 class="card-title">Quality Creation</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Name:</label>
          <div class="col-md-6">
            <input id="txtname" type="text" class="form-control" placeholder="Enter Quality Name here..." maxlength="100" autocomplete="off"  v-if="quality" v-model="quality.name" autofocus >
          </div>

          <label class="col-md-2 col-form-label font-weight-semibold">Rate Based On:</label>
          <div class="col-md-2">
            <select class="form-control" v-model="quality.rate_based_on">
              <option value="0" selected>Meter</option>
              <option value="1">Weight</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Colors:</label>
          <div class="col-md-10">

            <div id="divTable" class="table-responsive"  style="padding-bottom: 0px;">

              <table class="table table-bordered table-condensed table-columned table-responsive-md table-hover" style="padding-bottom: 0px;">
                <thead style="background-color: lightgrey">
                <tr>
                  <th style="width:50px;">S.No</th>
                  <th >Color</th>
                  <th style="width:100px; text-align: right;">Weight</th>
                </tr>
                </thead>
                <tbody v-for="(color,index) in quality.colorcharts" style="overflow-y;" >

                  <tr style="padding: 0px">
                    <td style="text-align: center;padding: 0px;"> {{index + 1}} </td>
                    <td style="padding: 0px">
                      {{color.colorchart.name}}
                    </td>

                    <td style="padding: 0px">
                      <input type="number" class="form-control text-right" placeholder="Weight In Kgs" max="100" v-if="color" v-model="color.weight" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>


      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>
  import FabButton from '@/components/core/FabButton.vue'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'QualityForm',
    data () {
      return {
        quality: JSON.parse('{"id":0,"name":"","status":"Active","rate_based_on":0,"weight":0,"colorcharts":[]}'),
        colorcharts: [],
        colorMap: new Map()
      }
    },
    props: {
      myquality: {
        type: Object,
        default: () => JSON.parse('{"id":0,"name":"","status":"Active","rate_based_on":0,"weight":0,"colorcharts":[]}')
      }
    },
    beforeMount () {
      this.quality = this.myquality; // save props data to itself's data and deal with this
    },
    component: {
      FabButton
    },
    created () {
      this.$data.quality = JSON.parse('{"id":0,"name":"","status":"Active","rate_based_on":0,"weight":0,"colorcharts":[]}')
    },
    mounted () {
      this.loadColorCharts();
      $('#txtname').focus();
    },
    methods: {
      closeWindow() {
        this.$emit('quality_window_closed');
      },
      loadColorCharts() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };


        $('#divTable').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.colorcharts = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/colorcharts/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){


            if( !_.isNull(resp.data)){

              if(self.$data.quality.colorcharts == null){
                self.$data.quality.colorcharts = [];
              }

              resp.data.forEach(function (col) {
                self.$data.colorMap.set(col.id, col);
              });
              self.$data.colorcharts = resp.data;


              //Clone the Colors Weight List
              let cols = new Map();
              self.$data.quality.colorcharts.forEach(function (col) {
                cols.set(col.colorchart.id, col.color);
              });

              self.$data.colorcharts.forEach(function (col) {
                if ( !cols.has(col.id)  ){
                  let colwgt = {"quality_id":0,"colorchart_id":0,"colorchart":{"id":0,"status":0,"name":"","weight":0},"weight":0};
                  colwgt.colorchart = col;
                  self.$data.quality.colorcharts.push(colwgt);
                }
              });
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oh noes" ,  text: err.toString(),  type: "error"});
        }).finally(()=> {
          $('#divTable').unblock();
        });

      },
      saveDocument(){
        const self = this;

        if(self.$data.quality.name.trim().length < 1){
          alert("Invalid Name");
          return
        }else if ( parseInt(self.$data.quality.rate_based_on) < 0 ) {
          alert("Invalid Rate Calc is not Selected");
          return
        }


        self.$data.quality.status = 'Active';

        self.$data.quality.colorcharts.forEach(function (col) {
          col.colorchart_id = col.colorchart.id;
          col.weight = parseFloat(col.weight);
        });

        const requestOptions = {
          method:  (self.$data.quality.id == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.quality)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}api/quality`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            swal ( { title: "Success!" ,  text: resp.msg, type:  "success", onClose: () => { $('#txtname').focus()}, timer:1500 } );
            self.quality = JSON.parse('{"id":0,"name":"","status":"Active","rate_based_on":0,"weight":0,"colorcharts":[]}');
            self.$emit('quality_saved', resp.data);
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error", onClose: () => { $('#txtname').focus()}, timer:3000 } );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error", onClose: () => { $('#txtname').focus()}, timer:3000 } );
        });

      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

  /*.modal-body {*/
  /*  overflow-y: scroll;*/
  /*}*/

  .table-responsive {
    max-height:250px;
  }
</style>
